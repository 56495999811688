<template>
  <user-view></user-view>
</template>

<script>
import UserView from './UserView.vue'

export default {
  components: {
    UserView,
  },

}
</script>
